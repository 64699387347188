import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

import {
  ApiService,
  AppService,
  RequestIds,
  ToastsService,
  UserService,
  ValidationService,
} from '../../core';
import { ErrorsState } from '../../shared';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['register.component.scss'],
})
export class RegisterComponent {
  form: FormGroup;
  isSubmitting = false;
  hidePassword = true;
  hideRepeatPassword = true;

  constructor (
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private toastsService: ToastsService,
    private fb: FormBuilder,
    private validationService: ValidationService,
    private translateService: TranslateService,
    private store: Store
  ) {
    this.appService.title = 'pages.signUp';

    this.form = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), this.validationService.matchValidator('password_confirmation', null, true)]],
      password_confirmation: ['', [Validators.required, this.validationService.matchValidator('password')]],
    });
  }

  submitForm () {
    if (!this.form.valid) {
      return;
    }
    this.isSubmitting = true;
    const values = Object.assign({}, this.form.getRawValue());
    this.userService.register(values).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.router.navigateByUrl('/login');
      },
      error: () => {
        const errors = this.store.selectSnapshot(ErrorsState.errors(RequestIds.PROFILE_UPDATE));
        for (const key in errors) {
          this.form.controls[key]?.setErrors({ invalid: true, message: errors[key] });
        }
        this.isSubmitting = false;
      },
    });
  }

  getIcon (active?: boolean): string {
    return active ? 'visibility_off' : 'visibility';
  }
}
