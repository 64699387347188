@if (withPassword) {
  <div class="registration-container">
    <div class="row justify-content-center mx-0">
      <div class="forms-container row justify-content-center">
        <div class="form-container col-12 col-md-6 me-0 me-md-4">
          <div class="form-container__text title f-32 mb-3">{{ 'pages.setPassword' | translate | titlecase }}</div>
          <form class="registration-form" [formGroup]="form">
            <app-input formControlName="password"
                       [config]="{ id: 'password', label: 'password', type: hidePassword ? 'password' : 'text', class: 'w-100' }"
                       [errors]="form.get('password').errors"
            >
              <button mat-icon-button
                      matSuffix (click)="hidePassword = !hidePassword"
                      [attr.aria-label]="'actions.hidePassword' | translate"
                      [attr.aria-pressed]="hidePassword">
                <mat-icon class="hide-password-icon">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
            </app-input>
            <app-input formControlName="password_confirmation"
                       [config]="{ id: 'password_confirmation', label: 'passwordConfirmation', type: hideRepeatPassword ? 'password' : 'text', class: 'w-100' }"
                       [errors]="form.get('password').errors"
            >
              <button mat-icon-button
                      matSuffix (click)="hideRepeatPassword = !hideRepeatPassword"
                      [attr.aria-label]="'actions.hidePassword' | translate"
                      [attr.aria-pressed]="hideRepeatPassword">
                <mat-icon class="hide-password-icon">{{ hideRepeatPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </button>
            </app-input>
            <div class="d-flex justify-content-center">
              <button mat-flat-button color="primary" type="submit" class="submit" (click)="confirm()"
                      [disabled]="isSubmitting || !form.valid">
                {{ 'actions.submit' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}
@if (!withPassword && loading) {
  <div>
    {{ 'messages.confirmationInProcess' | translate }}
  </div>
}
