<div class="registration-container">
  <div class="row justify-content-center mx-0">
    <div class="forms-container row justify-content-center">
      <div class="form-container col-12 col-md-6 me-0 me-md-4">
        <div class="form-container__text title f-32 mb-3">{{'pages.signUp' | translate | titlecase}}</div>
        <form class="registration-form" [formGroup]="form">
          <fieldset [disabled]="isSubmitting">
            <app-input formControlName="first_name"
                       [config]="{ id: 'first_name', label: 'firstName', class: 'w-100' }"
                       [errors]="form.get('first_name').errors"
                       [required]="true"
            ></app-input>
            <app-input formControlName="last_name"
                       [config]="{ id: 'last_name', label: 'lastName', class: 'w-100' }"
                       [errors]="form.get('last_name').errors"
                       [required]="true"
            ></app-input>
            <app-input formControlName="email"
                       [config]="{ id: 'email', label: 'email', type: 'email', class: 'w-100' }"
                       [errors]="form.get('email').errors"
                       [required]="true"
            ></app-input>
            <app-input formControlName="password"
                       [config]="{ id: 'password', label: 'password', type: hidePassword ? 'password' : 'text', class: 'w-100' }"
                       [errors]="form.get('password').errors"
                       [buttonConfig]="{icon: getIcon(hidePassword)}"
                       [required]="true"
                       (clickButton)="hidePassword = !hidePassword">
            </app-input>
            <app-input formControlName="password_confirmation"
                       [config]="{ id: 'password_confirmation', label: 'passwordConfirmation', type: hideRepeatPassword ? 'password' : 'text', class: 'w-100' }"
                       [errors]="form.get('password').errors"
                       [required]="true"
                       [buttonConfig]="{icon: getIcon(hideRepeatPassword)}"
                       (clickButton)="hideRepeatPassword = !hideRepeatPassword">
            </app-input>
            <div class="d-flex justify-content-center">
              <button mat-flat-button color="primary" type="submit" class="submit" (click)="submitForm()" [disabled]="isSubmitting || !form.valid">
                {{'actions.submit' | translate}}
              </button>
            </div>
          </fieldset>
        </form>
        <div class="form-container__text mt-3">
          {{'actions.alreadyHaveAccount' | translate}}
          <a class="link ms-2" [routerLink]="'/login'">{{'actions.signIn' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
