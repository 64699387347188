import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import {
  NoAuthGuard,
  PortalGuard,
} from '../shared';

import {
  ConfirmAccountComponent,
  ForgotPasswordComponent,
  LoginComponent,
  RegisterComponent,
  ResetPasswordComponent,
  SocialLoginComponent,
} from './';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
    data: { clearauth: true },
  },
  {
    path: 'login/:provider/callback',
    component: SocialLoginComponent,
  },
  {
    path: 'logout',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NoAuthGuard, PortalGuard],
    data: { clearauth: true, app: 'user' },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard],
    data: { clearauth: true },
  },
  {
    path: 'reset-password/:hash',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
    data: { clearauth: true },
  },
  {
    path: 'confirm/:hash/password',
    component: ConfirmAccountComponent,
    canActivate: [NoAuthGuard],
    data: { clearauth: true, password: true },
  },
  {
    path: 'confirm/:hash',
    component: ConfirmAccountComponent,
    canActivate: [NoAuthGuard],
    data: { clearauth: true, password: false },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
