<div class="registration-container">
  <div class="row justify-content-center mx-0">
    <div class="forms-container row justify-content-center">
      <div class="form-container col-12 col-md-6 me-0 me-md-4">
        <div class="form-container__text title f-32 mb-3">{{ 'pages.login' | translate }}</div>
        <form class="registration-form" [formGroup]="authForm" (ngSubmit)="submitForm()">
          <app-input formControlName="login"
                     [config]="{ id: 'login', label: 'email', type: 'email', class: 'w-100' }"
                     [errors]="authForm.get('login').errors"
          ></app-input>
          <app-input formControlName="password"
                     [config]="{ id: 'password', label: 'password', type: hidePassword ? 'password' : 'text', class: 'w-100' }"
                     [errors]="authForm.get('password').errors"
                     [buttonConfig]="{icon: passIcon}"
                     (clickButton)="hidePassword = !hidePassword">
          </app-input>
          <div class="d-flex justify-content-center">
            <button mat-flat-button color="primary" type="submit" class="submit form-button"
                    [disabled]="isSubmitting || !authForm.valid">{{ 'actions.submit' | translate }}
            </button>
          </div>
        </form>
      </div>
      <div class="form-container small col-12 col-md-6">
        <div class="form-container__text title f-32 mb-3">{{ 'actions.social.title' | translate }}</div>
        <app-social-buttons class="d-flex flex-column" [(isDisabled)]="isSubmitting"></app-social-buttons>
      </div>
    </div>
    <div class="w-100 justify-content-center">
      @if (isUnconfirmed) {
        <div class="form-container__text mt-3">
          <a class="link ms-2" (click)="sendConfirmation()">{{ 'actions.resendConfirmation' | translate }}</a>
        </div>
      }
      <div class="form-container__text mt-3">
        {{ 'actions.forgotPassword' | translate }}
        <a class="link ms-2" [routerLink]="'/forgot-password'">
          {{ 'actions.reset' | translate }}
        </a>
      </div>
      @if (!isAdminApp) {
        <div class="form-container__text mt-3">
          {{ 'actions.dontHaveAccount' | translate }}
          <a class="link ms-2" [routerLink]="'/register'">{{ 'actions.signUp' | translate }}</a>
        </div>
      }
    </div>
  </div>
</div>

