import {
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

import {
  AppService,
  AppType,
  RequestIds,
  SocialService,
  ToastsService,
  UserService,
  ValidationService,
} from '../../core';
import { HidePassword } from '../../core/models/icon.model';
import { ErrorsState } from '../../shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../register/register.component.scss', './login.component.scss'],
})
export class LoginComponent {
  @ViewChild('password') password: ElementRef;
  @ViewChild('login') login: ElementRef;

  isSubmitting = false;
  isUnconfirmed = false;
  authForm: FormGroup;
  hidePassword = true;
  isAdminApp: boolean;

  constructor(
    private appService: AppService,
    private userService: UserService,
    private fb: FormBuilder,
    public toastsService: ToastsService,
    private socialService: SocialService,
    private validationService: ValidationService,
    private translateService: TranslateService,
    private store: Store,
  ) {
    this.appService.title = 'pages.login';
    this.isAdminApp = AppService.appType === AppType.ADMIN;

    this.authForm = this.fb.group({
      login: [null, [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get passIcon() {
    return this.hidePassword ? HidePassword.OFF : HidePassword.ON;
  }

  submitForm() {
    if (!this.authForm.valid) {
      return;
    }
    this.isSubmitting = true;
    const credentials = this.authForm.value;
    credentials.login = credentials.login.replace(/\s/, '');
    this.userService.attemptAuth(credentials)
      .subscribe({
        next: () => {
          this.userService.profile().subscribe({
            next: () => {
              this.isSubmitting = false;
              this.userService.openHomePage();
            },
            error: () => {
              this.isSubmitting = false;
            },
          });
        }, error: e => {
          if (e.status === 403) {
            this.isUnconfirmed = true;
          }
          this.isSubmitting = false;
          const errors = this.store.selectSnapshot(ErrorsState.errors(RequestIds.USER_LOGIN));
          for (const key in errors) {
            this.authForm.controls[key]?.setErrors({ invalid: true, message: errors[key] });
          }
        },
      });
  }

  sendConfirmation() {
    if (!this.authForm.valid) {
      return;
    }
    this.userService.sendConfirmation(this.authForm.get('login').value).subscribe({
      next: () => this.toastsService.add(
        this.translateService
          .instant('messages.confirmationLinkSent', { email: this.authForm.get('login').value })
      ),
    });
  }
}
