<div class="registration-container">
  <div class="row justify-content-center mx-0">
    <div class="forms-container row justify-content-center">
      <div class="form-container col-12 col-md-6 me-0 me-md-4">
        <div class="form-container__text title f-32 mb-3">{{'pages.resetPassword' | translate}}</div>
        <form class="registration-form" [formGroup]="form">
          <app-input formControlName="email"
                     [config]="{ id: 'email', label: 'email', type: 'email', class: 'w-100' }"
                     [errors]="form.get('email').errors"
          ></app-input>
          <div class="d-flex justify-content-center">
            <button mat-flat-button color="primary" type="submit" class="submit" (click)="submitForm()"
                    [disabled]="isSubmitting || !form.valid">
              {{'actions.sendResetPasswordLink' | translate}}
            </button>
          </div>
        </form>
        <div class="form-container__text mt-3">
          <a class="link ms-2" [routerLink]="'/login'">{{'actions.backToLogin' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
